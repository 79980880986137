@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/akrobat');
@import 'normalize.css';

/** Defaults */
* {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
}

html,
body {
  height: 100vh;
  width: 100vw;
  color: black;
  overflow: hidden;
  font-size: 14px; /** 1em ou 1rem === 14px */
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
/** Defaults */

/** card-titulo */
.card-titulo {
  color: #000 !important;
  font-weight: 400 !important;
  background: #FFFFFF !important;
  height: 50px !important;
  max-height: 49px !important;
  min-height: 49px !important;
}

/** content */
.content {
  box-shadow: inset -2px 0px 2px #00000012 !important;
}

/** CardFilters **/
/** botão filtrar */

/** cardFilter container */
.css-1ranrno-MuiPaper-root-MuiAccordion-root {
  border: none !important;
  box-shadow: none !important;
}

/** card-filters-filtrar */
.card-filters-filtrar button {
  border-radius: 16px !important;
  background: rgba(136, 190, 206, 0) !important;
  padding: 6px 18px !important;
  font-size: 14px !important;
  color: #8B0909 !important;
  text-align: center;
  min-width: 98px !important;
  font-weight: 600 !important;
  font-family: 'Open sans' !important;
}

/** label filtros */
.expanded-icon-label {
  font-size: 18px !important;
  margin-right: 10px !important;
}

/** margem do header */
.card-filters-header {
  margin: 0px !important;
}

/** espaçamento botões de ação */
.card-filters-actions .MuiButton-root {
  padding: 0px !important;
  min-width: 24px !important;
  margin-right: 27px !important;
}

/** cor botão add filter */
.card-filters-add svg {
  color: rgb(0, 0, 0) !important;
}

/** cor botão clear filter */
.card-filters-clean svg {
  color: rgb(0, 0, 0) !important;
}

/** container dos inputs */
.card-filters-inputs-container {
  padding: 20px 25px 0 !important;
  height: 85px !important;
}

/** tamanho dos inputs */
.card-filters-inputs-container .MuiGrid-item {
  padding-right: 27px !important;
  margin-bottom: 10px;
}

/** altera o primeiro select */
.card-filters-tipo-filtro {
  flex-basis: 0% !important;
  min-width: 202px !important;
}

.card-filters-clean {
  color: #F7375C !important;
}

.card-filters-footer {
  padding: 16px 25px 0px 25px !important;
  margin-bottom: -16px !important;
}

/** adicionando margens aos chips quando o cardFilters está aberto */
.MuiAccordionActions-spacing .MuiChip-root {
  margin: 0px 6px 6px !important;
}

/** label filtros adicionados */
.label-filter-chips-closed {
  margin-bottom: 6px !important;
  margin-right: 6px !important;
  color: #95A2B9 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

/* altura do header */
.card-filters-header {
  height: 50px !important;
  max-height: 50px !important;
  min-height: 50px !important;
  padding: 0 25px !important;
  color: #4E545B !important; 
  border-bottom: 1px solid #E4E4E4 !important;
}

/** label do chip */
.MuiChip-root span {
  font-size: 12px;
  font-family: 'Open Sans';
  color: #33384F !important;
  line-height: 17px !important;
}

/** titulo do chip */
.MuiChip-root b {
  font-weight: bold;
}

/** CardFilters **/

/** DataTable */
.ag-row-selected {
  border-bottom: 2px solid rgba(84, 0, 0, .22) !important;
  background-color: rgba(84, 0, 0, .15) !important;
}

.ag-row-odd:not(.ag-row-selected) {
  background-color: rgba(195, 210, 227, 0.12) !important;
  border-bottom: 1px solid #D9D9D9 !important;
}

.ag-row-even:not(.ag-row-selected) {
  background-color: #FFFFFF !important;
  border-bottom: 1px solid #D9D9D9 !important;
}

/** botão de editar */
.button-grid {
  margin-right: 30px !important;
}

/** sombra da tabela */
.tabela .MuiPaper-elevation {
  box-shadow: none !important;
}

/** borda paginação */
.MuiTableCell-root {
  border: none !important;
}

.ag-center-cols-viewport {
  height: calc(100% + 18px) !important;
}
/* fonte */
.ag-header-cell-text {
  font-family: 'Open Sans' !important;
  color: rgba(0, 0, 0, 0.75) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
}
/** border-top da primeira linha da tabela */
.ag-row-first {
  border-top: 1px solid #D9D9D9 !important;
}
/** border-bottom da tabela */
.ag-theme-material {
  box-shadow: none !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 14px !important;
  font-weight: 400;
  cursor: pointer;
  color: #95A2B9;
  opacity: 1 !important;
}

.ag-header-row {
  border: none !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #D9D9D9 !important; 
}

/* checkbox */
.ag-theme-material .ag-checkbox-input-wrapper::after {
  color: rgb(0, 0, 0, .75) !important;
}
.ag-theme-material .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: rgb(0, 0, 0, .75) !important;
}
.ag-checkbox {
  background-color: #ffffff !important;
} 
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #8B0909 !important;
}
/* checkbox */

.MuiButton-containedPrimary .MuiButton-disableElevation {
  margin-right: 30px !important;
}

.ag-cell-not-inline-editing {
  color: #261b1b !important;
  font-family: 'Open Sans';
}

.ag-header-viewport {
  border-radius: 0px !important;
  height: 50px !important;
}

.ag-header-active {
  background-color: transparent !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  font-family: 'Open Sans' !important;
  color: #000000 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}
.css-hdf2w1-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select {
  color: #000000 !important;
}
.ag-header {
  border: none !important;
  min-height: 50px !important;
  height: 50px !important;
  padding: 0 !important;
  background-color: rgba(147, 162, 184, .15) !important;
  
}
.ag-header-container {
  border-top: 1px solid #C3D2E3 !important ;
}
.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}
/** DataTable */

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(9, 9, 9, 0);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}
/* Custom Scrollbar */

/* Border-bottom da tabela */
.ag-theme-material .ag-root-wrapper {
  border-bottom: 1px solid #D9D9D9!important;
  margin: 0 24px !important;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
/* .swal2-container .swal2-center .swal2-backdrop-show*/
.swal2-container .swal2-backdrop-show {
  position: absolute !important;
  z-index: 10 !important; 
}
