@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.cdnfonts.com/css/akrobat);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/* User interaction
 * ========================================================================== */

/** Defaults */
* {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
}

html,
body {
  height: 100vh;
  width: 100vw;
  color: black;
  overflow: hidden;
  font-size: 14px; /** 1em ou 1rem === 14px */
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
/** Defaults */

/** card-titulo */
.card-titulo {
  color: #000 !important;
  font-weight: 400 !important;
  background: #FFFFFF !important;
  height: 50px !important;
  max-height: 49px !important;
  min-height: 49px !important;
}

/** content */
.content {
  box-shadow: inset -2px 0px 2px #00000012 !important;
}

/** CardFilters **/
/** botão filtrar */

/** cardFilter container */
.css-1ranrno-MuiPaper-root-MuiAccordion-root {
  border: none !important;
  box-shadow: none !important;
}

/** card-filters-filtrar */
.card-filters-filtrar button {
  border-radius: 16px !important;
  background: rgba(136, 190, 206, 0) !important;
  padding: 6px 18px !important;
  font-size: 14px !important;
  color: #8B0909 !important;
  text-align: center;
  min-width: 98px !important;
  font-weight: 600 !important;
  font-family: 'Open sans' !important;
}

/** label filtros */
.expanded-icon-label {
  font-size: 18px !important;
  margin-right: 10px !important;
}

/** margem do header */
.card-filters-header {
  margin: 0px !important;
}

/** espaçamento botões de ação */
.card-filters-actions .MuiButton-root {
  padding: 0px !important;
  min-width: 24px !important;
  margin-right: 27px !important;
}

/** cor botão add filter */
.card-filters-add svg {
  color: rgb(0, 0, 0) !important;
}

/** cor botão clear filter */
.card-filters-clean svg {
  color: rgb(0, 0, 0) !important;
}

/** container dos inputs */
.card-filters-inputs-container {
  padding: 20px 25px 0 !important;
  height: 85px !important;
}

/** tamanho dos inputs */
.card-filters-inputs-container .MuiGrid-item {
  padding-right: 27px !important;
  margin-bottom: 10px;
}

/** altera o primeiro select */
.card-filters-tipo-filtro {
  flex-basis: 0% !important;
  min-width: 202px !important;
}

.card-filters-clean {
  color: #F7375C !important;
}

.card-filters-footer {
  padding: 16px 25px 0px 25px !important;
  margin-bottom: -16px !important;
}

/** adicionando margens aos chips quando o cardFilters está aberto */
.MuiAccordionActions-spacing .MuiChip-root {
  margin: 0px 6px 6px !important;
}

/** label filtros adicionados */
.label-filter-chips-closed {
  margin-bottom: 6px !important;
  margin-right: 6px !important;
  color: #95A2B9 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

/* altura do header */
.card-filters-header {
  height: 50px !important;
  max-height: 50px !important;
  min-height: 50px !important;
  padding: 0 25px !important;
  color: #4E545B !important; 
  border-bottom: 1px solid #E4E4E4 !important;
}

/** label do chip */
.MuiChip-root span {
  font-size: 12px;
  font-family: 'Open Sans';
  color: #33384F !important;
  line-height: 17px !important;
}

/** titulo do chip */
.MuiChip-root b {
  font-weight: bold;
}

/** CardFilters **/

/** DataTable */
.ag-row-selected {
  border-bottom: 2px solid rgba(84, 0, 0, .22) !important;
  background-color: rgba(84, 0, 0, .15) !important;
}

.ag-row-odd:not(.ag-row-selected) {
  background-color: rgba(195, 210, 227, 0.12) !important;
  border-bottom: 1px solid #D9D9D9 !important;
}

.ag-row-even:not(.ag-row-selected) {
  background-color: #FFFFFF !important;
  border-bottom: 1px solid #D9D9D9 !important;
}

/** botão de editar */
.button-grid {
  margin-right: 30px !important;
}

/** sombra da tabela */
.tabela .MuiPaper-elevation {
  box-shadow: none !important;
}

/** borda paginação */
.MuiTableCell-root {
  border: none !important;
}

.ag-center-cols-viewport {
  height: calc(100% + 18px) !important;
}
/* fonte */
.ag-header-cell-text {
  font-family: 'Open Sans' !important;
  color: rgba(0, 0, 0, 0.75) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
}
/** border-top da primeira linha da tabela */
.ag-row-first {
  border-top: 1px solid #D9D9D9 !important;
}
/** border-bottom da tabela */
.ag-theme-material {
  box-shadow: none !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 14px !important;
  font-weight: 400;
  cursor: pointer;
  color: #95A2B9;
  opacity: 1 !important;
}

.ag-header-row {
  border: none !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #D9D9D9 !important; 
}

/* checkbox */
.ag-theme-material .ag-checkbox-input-wrapper::after {
  color: rgb(0, 0, 0, .75) !important;
}
.ag-theme-material .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: rgb(0, 0, 0, .75) !important;
}
.ag-checkbox {
  background-color: #ffffff !important;
} 
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #8B0909 !important;
}
/* checkbox */

.MuiButton-containedPrimary .MuiButton-disableElevation {
  margin-right: 30px !important;
}

.ag-cell-not-inline-editing {
  color: #261b1b !important;
  font-family: 'Open Sans';
}

.ag-header-viewport {
  border-radius: 0px !important;
  height: 50px !important;
}

.ag-header-active {
  background-color: transparent !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  font-family: 'Open Sans' !important;
  color: #000000 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}
.css-hdf2w1-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select {
  color: #000000 !important;
}
.ag-header {
  border: none !important;
  min-height: 50px !important;
  height: 50px !important;
  padding: 0 !important;
  background-color: rgba(147, 162, 184, .15) !important;
  
}
.ag-header-container {
  border-top: 1px solid #C3D2E3 !important ;
}
.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}
/** DataTable */

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(9, 9, 9, 0);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}
/* Custom Scrollbar */

/* Border-bottom da tabela */
.ag-theme-material .ag-root-wrapper {
  border-bottom: 1px solid #D9D9D9!important;
  margin: 0 24px !important;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
/* .swal2-container .swal2-center .swal2-backdrop-show*/
.swal2-container .swal2-backdrop-show {
  position: absolute !important;
  z-index: 10 !important; 
}

/** LoadingSwal **/
.container-swal {
  width: 100px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/** LoadingSwal **/

/** Swal **/
.container-label {
  width: 450px;
}
/** Swal */

.run-write-close {
  transition: opacity 0.5s linear;
  opacity: 0;
}

.run-write-open {
  transition: opacity 0.5s linear;
  opacity: 1;
}
.run-write-close-sucess {
  transition: opacity 0.5s linear;
  transition-delay: 0.5s;
  opacity: 1;
}

.run-write-open-sucess {
  opacity: 0;
}

